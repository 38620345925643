export const LANGUAGES = {
  es: {
    indexDetailUrl : {
      SPBL25PT: '/mercado/indices/indice-spbvl-lima-25',
      SPBLPGPT : '/mercado/indices/indice-spbvl-peru-general',
      SPBLPSPT : '/mercado/indices/indice-spbvl-peru-select',
      SPBLBGPT: '/mercado/indices/indice-spbvl-ibgc',
      SPBPGEPT: '/mercado/indices/indice-spbvl-peru-general-esg',
      SPBLFPT: '/mercado/indices/indice-spbvl-financials-real-estate',
      SPBLIPT: '/mercado/indices/indice-spbvl-industrials',
      SPBLMPT: '/mercado/indices/indice-spbvl-mining',
      SPBLSPT: '/mercado/indices/indice-spbvl-public-services',
      SPBLCPT: '/mercado/indices/indice-spbvl-consumer',
      SPBLEPT: '/mercado/indices/indice-spbvl-electric-utilities',
      SPBLNPT: '/mercado/indices/indice-spbvl-construction',
      SPBLJPT: '/mercado/indices/indice-spbvl-juniors',
      SPBLSCPT: '/mercado/indices/indice-spbvl-peru-select-20-capped',
      SPBVPSPT: '/mercado/indices/indice-spbvl-peru-smallcap',
      SPBVSDPT: '/mercado/indices/indice-spbvl-enhanced-dividend',
      SPBLECPT: '/mercado/indices/indice-spbvl-peru-general-esg-20',
      SPBVLIPT: '/mercado/indices/indice-spbvl-ingenius',
      MXNUAMIDX: '/mercado/indice/indice-msci-nuam-usd'
    },
    home: {
      general: '/',
      education: '/educacion'
    },
    issuersPopover: {
      memory : 'Más Memorias:',
      indexes : 'Pertenece a Índices:',
      mnemonic : 'Más Acciones:',
      important: 'Hechos de Importancia:'
    },

    searchPageSite : {
      title : 'Búsqueda de Páginas',
      form: {
        search : 'Buscar'
      },
      results : {
        withResponse: 'Resultados para busqueda: ',
        withoutResponse : 'No se encontraron resultados',
        itemNoDescription: 'No se encontro una descripción registrada',
        setKeyWords: 'Introduzca una palabra clave para realizar una búsqueda'
      }
    },

    contactus : {
      title : 'Contáctenos',
      form : {
        nameAndLastName: 'Nombre y apellido',
        subject: 'Asunto',
        phone: 'Teléfono',
        message: 'Mensaje',
        email: 'Email',
        termsAndConditions: {
          iHaveReadAndAcceptThe: 'He leído y acepto los',
          termsAndConditions: 'términos y condiciones'
        }
      },
      message : {
        success: 'Su mensaje ha sido enviado, gracias por contactarnos.',
        error: 'Ocurrio un error por favor vuelva a intentarlo.'
      },
      buttons: {
        send : 'Enviar',
        contact: 'Contactar'
      },
      modal: {
        termsAndConditions: [
          {
            title: 'Título',
            // tslint:disable-next-line: max-line-length
            description: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Itaque quae dicta magnam soluta recusandae fugit eveniet aspernatur debitis autem, architecto vel, maxime voluptatem maiores officia natus omnis quidem molestias corporis?'
          },
          {
            title: 'Título',
            // tslint:disable-next-line: max-line-length
            description: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Itaque quae dicta magnam soluta recusandae fugit eveniet aspernatur debitis autem, architecto vel, maxime voluptatem maiores officia natus omnis quidem molestias corporis?'
          }
        ]
      }
    },

    filters: {
      datesRange: {
        day: 'Día',
        month: 'Mes',
        months: 'Meses',
        year: 'Año',
        noData: 'No hay datos en este rango de fechas'
      },
      all: 'Todos',
      onlyToday: 'Solo hoy',
      noData: 'No hay resultados para su búsqueda'
    },
    tables: {
      company: 'Empresa',
      sector: 'Sector',
      seg: 'Seg.',
      ant: 'Ant.',
      prevDate: 'Fecha ant.',
      last: 'Última',
      var: 'Var',
      buy: 'Compra',
      sell: 'Venta',
      amount: 'Monto',
      noOp: 'N°Op',
      open: 'Apert.',
      newInfo: 'Última info.',
      quantity: 'Cantidad Neg.'
    },
    codification: {
      bySectorIndustry: 'Por Sector/Industria',
      byAlphabet: 'Por Alfabeto',
      listCompaniesByStart: 'Lista de compañias que empiecen con:',
      mode: 'Modo',
      nemonic: 'Nemónico',
      group: 'Grupo',
      type: 'Tipo',
      currency: 'Moneda',
      nominalValue: 'Valor Nominal',
      rate: 'Tasa'
    },
    issuers: {
      outstandingShares: 'Nº de acciones en circulación',
      capitalization: 'Capitalización',
      bagNewsAlert: 'Alerta bolsa news',
      sendReport: 'Enviar informe',
      addWatchlist: 'Añadir a Watchlist',
      realTimeBagPlay: 'Información en tiempo real con Abi',
      dateListingBvl: 'Fecha de listado en la BVL',
      corporativeInfo: 'Info corporativa',
      value: 'Valores Inscritos',
      quotationHistory: 'Histórico de cotizaciones',
      importantFacts: 'Hechos de importancia',
      importantNotices: 'Avisos de interés',
      finantialInfo: 'Información financiera',
      memories: 'Memorias',
      closeToMe: 'Cerca de mí'
    },
    benefits: {
      lastBenefits: 'Últimos beneficios',
      rights: 'Derechos',
      agreement: 'Acuerdo',
      cut: 'Corte',
      registry: 'Registro',
      delivery: 'Entrega'
    },
    general: {
      search: 'Buscar',
      messages: 'Mensajes',
      servicesStatus: 'Estado de servicios',
      profile: 'Perfil',
      filterByProfile: 'Filtrar información por perfil',
      education: 'Educación',
      viewAll: 'Ver todos',
      viewMore: 'Ver más',
      viewLess: 'Ver menos',
      viewDocument: 'Ver documentos',
      download: 'Descargar',
      downloads: 'Descargables',
      findYourSab: 'Encuentra tu SAB',
      file: 'Archivo',
      value: 'Valor',
      date: 'Fecha',
      hour: 'Hora',
      rise: 'Mayores Alzas',
      low: 'Mayores bajas',
      follow: 'Seguir',
      phones: 'Teléfonos',
      webSite: 'Página Web',
      manager: 'Gerente General',
      representative: 'Representante',
      office: 'Oficina',
      offices: 'Oficinas',
      basicInformation: 'Información básica',
      authorization: 'Autorización',
      clientPoliticsMemories: 'Política de clientes y memorias',
      clientSantions: 'Registro de Sanciones',
      sanctions: 'Registro de Sanciones',
      // tslint:disable-next-line:max-line-length
      sanctionsDescripcion: 'Este registro contiene las sanciones administrativas que la SMV aplica a las Sociedades Agentes de Bolsa, sus apoderados, operadores y demás representantes (artículo 147 de la Ley del Mercado de Valores)',
      myVideos: 'Mis Videos',
      dailyMovements: 'Negociación diaria',
      turnPhone: 'Gira tu teléfono para una mejor experiencia',
      quotation: 'Cotización',
      quotations: 'Cotizaciones',
      compare: 'Comparar',
      of: 'de',
      price: 'Precio',
      current: 'Actual',
      currentPrice: 'Precio actual',
      negotiationPriceToday: 'Precio negociación hoy',
      opening: 'Apertura',
      closing: 'Cierre',
      max: 'Máximo',
      min: 'Mínimo',
      average: 'Promedio',
      negotiatedQuantity: 'Cantidad negociada',
      negotiatedAmount: 'Monto negociado',
      yesterday: 'Fecha anterior',
      yesterdayClose: 'Cierre anterior corregido',
      variation: 'Variación',
      moreInfo: 'Saber más',
      address: 'Dirección',
      businessName: 'Razón social',
      fondoBusinessName: 'Denominación del Fondo de Inversión',
      foundation: 'Fundación',
      dateStartOperation: 'Fecha de inicio de operaciones',
      description: 'Descripción',
      stockComposition: 'Composición del accionario',
      directory: 'Directorio',
      committee: 'Comité',
      generalManager: 'Gerente General',
      indexes: 'Índices',
      index: 'Índice',
      issuers: 'Emisores',
      actions: 'Acciones',
      buy: 'Comprar',
      stock: 'Bursátiles',
      marketSummary: 'Resumen de mercado',
      today: 'Hoy',
      operations: 'Operaciones',
      operation: 'Operación',
      amountIn: 'Monto en',
      dolars: 'Dólares',
      see: 'Ver',
      close: 'Cerrar',
      representatives: 'Representantes',
      LastTradingDay: 'Último día bursátil',
      lastMovement: 'Última transacción',
      comparisonLimit: 'Limite de comparación',
      lastDayDecember: '31 de Diciembre',
      source: 'Fuente',
      annualMemory: 'Memoria anual',
      quotationByTime: 'Los datos de cotización tienen un retraso de 20 min',
      getRealTimeTime: 'Obtener información en tiempo real',
      urlRealTime: '/abi',
      noData: 'En este momento, no hay datos disponibles',
      noTradedShared: 'Nro. Instr. o lotes negociados',
      add: 'Añadir',
      remove: 'Remover',
      since: 'Desde',
      until: 'Hasta',
      bvlNews: 'Noticias de Bolsa',
      latest: 'Último',
      financialIndexes: 'Indices Financieros',
      typeContent: 'Tipo de contenido',
      whatIs: '¿Qué es?',
      statistics: 'Estadísticas',
      components: 'Componentes',
      downloadDocumentos: 'Documentos Descargables',
      frequentQuestions: 'Preguntas frecuentes',
      requirements: 'Requisitos',
      subjectDetail: 'Detalle del asunto',
      periodByYear: 'Período por año',
      first: 'Primer',
      second: 'Segundo',
      third: 'Tercer',
      fourth: 'Cuarto',
      trimester: 'Trimestre',
      annual: 'Anual',
      toUp: 'Al alza',
      same: 'Sin variación',
      toDown: 'A la baja',
      nemonics: 'Nemonicos',
      variableRent: 'Renta Variable',
      dailyBulletin: 'Boletín diario',
      financialStatements: 'Estados financieros',
      stockInformation: 'Informe mensual',

      stockInformationHistorical: 'Informes mensuales en Formato PDF',
      stockInformationCurrent: '',
      stockInformationAnexo: 'Anexo',

      goBack: 'volver',
      goBackCapitalize: 'Volver',
      next: 'Siguiente',
      finish: 'Terminar',
      period: 'Período',
      audit: 'Auditados',
      rule: 'Norma',
      socialDenomination: 'Denominación Social',
      sommelier: 'Sumilla',
      document: 'Documento',
      unknown: 'DESCONOCIDO',
      butteinYear: 'BOLETIN AÑO',
      latestNewsletter: 'Último boletin: ',
      onboarding: 'onboarding',
      searchCompany: 'Buscar empresa',
      subjectType: 'Tipo de asunto',
      part: 'Parte',
      feesNumber: 'Nro de cuotas',
      termsAndConditions: 'Términos y Condiciones',
      negotitationByMechanism: 'Negociación por mecanismo',
      operationsNumber: 'Nro. Oper.',
      total: 'Total',
      market: 'Mercado',
      numberActions: 'Nro de acciones',
      nominalAmount: 'Monto nominal',
      effectiveAmount: 'Monto Efectivo',
      socialCapital: 'Capital social',
      offExchangeTradingOperations: 'Operaciones extrabursátiles',
      negociatedAmountsOperationsNumber: 'Montos Negociados y Número de Operaciones',
      marketFormerValues: 'Valores con Formador de Mercado',
      marketFormer: 'Formador de Mercado',
      minimunAmount: 'Cantidad mínima',
      without: 'sin',
      with: 'con',
      former: 'formador',
      dailyInformation: 'Información del día',
      formerMarketValues: 'Valores con Formador de Mercado',
      localMarket: 'Mercado local',
      foreignMarket: 'Mercado extranjero',
      localValue: 'Valores locales',
      foreignValue: 'Valores extranjeros',
      summaryNegotiatedAmountsOperations: 'Resumen de Montos Negociados y Operaciones',
      in: 'en',
      the: 'el',
      peruvianIntermediaries: 'intermediarios peruanos',
      negotitation: 'negociación',
      fromIntermediaries: 'Mercado Destino',
      intermediariesFrom: 'Intermediarios provenientes de',
      action: 'Acción',
      referencePrice: 'Precio de Referencia',
      updatedPrice: 'Precio actualizado',
      mainWarranty: 'Garantía principal',
      additionalGuarantee: 'Garantía adicional',
      valuationPrices: 'Precios de Valorización',
      calcDate: 'Fecha de calculo',
      effectiveDate: 'Fecha<br />vigente',
      presence: 'Presencia',
      registrationDate: 'Fecha de Inscripción',
      stockPresence: 'Presencia bursátil',
      historical: 'Historica',
      fixedRent: 'Renta fija',
      prevDailyNewsletters: 'Boletines diarios anteriores',
      newValuesMarketFormer: 'Valores nuevos con formador de mercado',
      valueName: 'Nombre del Valor',
      comment: 'Comentario',
      variableIncome: 'Renta Variable',
      precenseLevel: 'Nivel de <br />presencia',
      informationDay: 'Información actualizada al ',
      information: 'Información',
      negociatedAmountsSellBuy: 'Los montos negociados consideran compras y ventas de valores peruanos.',
      loanValue: 'Valor objeto de préstamo',
      guarantee: 'Garantías',
      dailyTotals: 'Totales diarios',
      // tslint:disable-next-line: max-line-length
      newMakerFormetParagraph1: '1. Referida a la cantidad de ruedas en que un valor tuvo negociación por más de 6 UITs en las últimas 180 ruedas, expresado en %.',
      // tslint:disable-next-line: max-line-length
      newMakerFormetParagraph2: '2. Valores inscritos en el Registro de Valores de la Bolsa de Valores de Lima con inscripción de hasta 360 días y que cuentan con Formador de Mercado, para dar cumplimiento a la Ley 30341, su Reglamento aprobado por Decreto Supremo N° 382-2015-EF y sus normas modificatorias; para el caso de instrumentos de emisión menor a180 días, se muestran aquellos valores que tienen hasta 180 de inscripción en el Registro de Valores de la Bolsa de Valores de Lima y que cuentan con Formador de Mercado. El Estado de la SAB corresponde a su estado en su función como Formador de Mercado.',
      sabPlaceholder: 'Encuentra la SAB por nombre o por ubicación presionando el botón',
      functions: 'Funciones',
      functionsList: 'Listado de funciones',
      newMarketFormerNoData: 'En este momento no hay nuevos valores con formador de mercado',
      pageNotFound: 'Página no encontrada',
      backHome: 'Volver al HOME',
      issuerName: 'Nombre del Emisor',
      milaValuesTitle: 'MILA: Relación de valores %COUNTRY% que podrán ser negociados en Perú',
      peruvians: 'Peruanos',
      mexicans: 'Mexicanos',
      chileans: 'Chilenos',
      colombians: 'Colombianos',
      shareholder: 'Accionista',
      day: 'día',
      investmentFunds: 'Fondos de Inversión',
      scrowFunds: 'Patrimonios en Fideicomisos',
      selectedDocuments: 'Documentos Seleccionados',
      accept: 'Aceptar',
      cancel: 'Cancelar',
      quantity: 'Cantidad',
      orderBy: 'Ordenar por',
      presentedInformation: 'Información Presentada',
      select: 'Seleccione',
      clean: 'Limpiar',
      excercise: 'Ejercicio',
      patrimonies: 'Patrimonios',
      upgrade: 'actualización',
      exchangeRate: 'Tipo de cambio',
      lastUpdate: 'Última actualización',
      fullScreen: 'Ver pantalla completa',
      hideFullScreen: 'Pantalla normal',
      name: 'Nombre',
      mainMarket: 'Mercado Principal',
      inscriptionDate: 'Fecha de Inscripción/Deslistado',
      monthsNoNegotiations: 'Meses sin Negociar',
      createdBy: 'Inscrito por:',
      extraInformation: 'Información Adicional',
      localIssuersInstruments: 'Emisores e Instrumentos Locales',
      foreignIssuersInstruments: 'Valores Extranjeros',
      negotiationInformation: 'Info. Negociación',
      listByBVL: 'Listado en BVL por',
      originMarket: 'Mercado de Origen',
      listBVLdate: 'Fecha de listado en la BVL',
      attachmentDetail: 'Detalle del adjunto',
      documentDetail: 'Detalle del documento',
      bvlPrivacy: 'En la BVL valoramos tu privacidad',
      privacyAgreementFirst: 'Esta web usa cookies propias que tienen una finalidad funcional y cookies de terceros que permiten conocer sus hábitos de navegación para darle mejores servicios de información. Para continuar navegando, es importante que acepte su uso.',
      privacyAgreementSecond: 'Al seguir utilizando nuestro sitio web y dar clic en “Aceptar”, estás dando tu consentimiento para el uso de estas tecnologías',
      privacyPolitcies: 'Políticas de privacidad',
      readMore: 'Leer más',
      seeRepresentativesAgentList: 'Ver representantes autorizados por la SMV',
      seeSanctionsAgentList: 'Ver registro de sanciones SMV',
      seePoliciesAgentList: 'Políticas de Cliente',
      seeMemoriesAgentList: 'Memorias',
      detailAgentListUri: 'detalle',
    }
  },
  en: {
    indexDetailUrl : {
      SPBL25PT: '/en/market/index/index-spbvl-lima-25',
      SPBLPGPT : '/en/market/index/index-spbvl-peru-general',
      SPBLPSPT : '/en/market/index/index-spbvl-peru-select',
      SPBLBGPT: '/en/market/index/index-spbvl-ibgc',
      SPBPGEPT: '/en/market/spbvl-peru-general-esg-index',
      SPBLFPT: '/en/market/index/index-spbvl-financials-real-estate',
      SPBLIPT: '/en/market/index/index-spbvl-industrials',
      SPBLMPT: '/en/market/index/index-spbvl-mining',
      SPBLSPT: '/en/market/index/index-spbvl-public-services',
      SPBLCPT: '/en/market/index/index-spbvl-consumer',
      SPBLEPT: '/en/market/index/index-spbvl-electric-utilities',
      SPBLNPT: '/en/market/index/index-spbvl-construction',
      SPBLJPT: '/en/market/index/index-spbvl-juniors',
      SPBLSCPT: '/en/market/indexes/spbvl-peru-select-20-capped-index',
      SPBVPSPT: '/en/market/indexes/spbvl-peru-smallcap-index',
      SPBVSDPT: '/en/market/indexes/spbvl-enhanced-dividend-index',
      SPBLECPT: '/en/market/indexes/spbvl-peru-general-esg-20',
      SPBVLIPT: '/en/market/indexes/spbvl-ingenius-index'
    },
    home: {
      general: '/en',
      education: '/en/education'
    },
    issuersPopover: {
      memory : 'More Memories:',
      indexes : 'It belongs to Indices:',
      mnemonic : 'More Actions:',
      important: 'Important Facts:'
    },
    searchPageSite: {
      title: 'Search for Pages',
      form: {
        search: 'Search'
      },
      results: {
        withResponse: 'Search results:',
        withoutResponse: 'No results found',
        itemNoDescription: 'No registered description found',
        setKeyWords: 'Enter a keyword to search'
      }
    },
    contactus : {
      title : 'Contact Us',
      form : {
        nameAndLastName: 'Name and Lastname',
        subject: 'Subject',
        phone: 'Phone',
        message: 'Message',
        email: 'E-mail',
        termsAndConditions: {
          iHaveReadAndAcceptThe: 'I have read and accept the',
          termsAndConditions: 'terms and conditions'
        }
      },
      message : {
        success: 'Your message has been sent, thanks for contacting us.',
        error: 'An error occurred please try again.'
      },
      buttons: {
        send : 'Send',
        contact: 'Contact'
      },
      modal: {
        termsAndConditions: [
          {
            title: 'Title',
            // tslint:disable-next-line: max-line-length
            description: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Itaque quae dicta magnam soluta recusandae fugit eveniet aspernatur debitis autem, architecto vel, maxime voluptatem maiores officia natus omnis quidem molestias corporis?'
          },
          {
            title: 'Title',
            // tslint:disable-next-line: max-line-length
            description: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Itaque quae dicta magnam soluta recusandae fugit eveniet aspernatur debitis autem, architecto vel, maxime voluptatem maiores officia natus omnis quidem molestias corporis?'
          }
        ]
      }
    },
    filters: {
      datesRange: {
        day: 'Day',
        month: 'Month',
        months: 'Months',
        year: 'Year',
        noData: 'No data for this range of dates'
      },
      all: 'All',
      onlyToday: 'Only today',
      noData: 'No results'
    },
    tables: {
      company: 'Company',
      sector: 'Sector',
      seg: 'Seg.',
      ant: 'Prev.',
      prevDate: 'Prev Date.',
      last: 'Last',
      var: 'Var',
      buy: 'Buy',
      sell: 'Sell',
      amount: 'Amount',
      noOp: 'N°Op',
      open: 'Open',
      newInfo: 'Last Info.',
      quantity: 'Negotiated Quan.'
    },
    codification: {
      bySectorIndustry: 'By Sector/Industry',
      byAlphabet: 'By Alphabet',
      listCompaniesByStart: 'List of companies that start with',
      mode: 'Mode',
      nemonic: 'Nemonic',
      group: 'Group',
      type: 'Type',
      currency: 'Currency',
      nominalValue: 'Nominal Value',
      rate: 'Rate'
    },
    issuers: {
      outstandingShares: 'No. of shares outstanding',
      capitalization: 'Capitalization',
      bagNewsAlert: 'Bag news alert',
      sendReport: 'Send Report',
      addWatchlist: 'add to Watchlist',
      realTimeBagPlay: 'Abi on real time',
      dateListingBvl: 'date of listing in the bvl',
      corporativeInfo: 'Corporate info',
      value: 'Registered Values',
      quotationHistory: 'Quotation history',
      importantFacts: 'Important facts',
      importantNotices: 'Important Notices',
      finantialInfo: 'Financial Info',
      memories: 'Memories',
      closeToMe: 'Close to me'
    },
    benefits: {
      lastBenefits: 'Last Benefits',
      rights: 'Rights',
      agreement: 'Agreement',
      cut: 'Cut',
      registry: 'Registry',
      delivery: 'Delivery'
    },
    general: {
      search: 'Search',
      messages: 'Messages',
      servicesStatus: 'Services status',
      profile: 'Profile',
      filterByProfile: 'Filter information by profile',
      education: 'Education',
      viewAll: 'View all',
      viewMore: 'View more',
      viewLess: 'View less',
      viewDocument: 'See documents',
      download: 'Download',
      downloads: 'Downloads',
      findYourSab: 'Find your SAB',
      file: 'File',
      value: 'Value',
      date: 'Date',
      hour: 'Hour',
      rise: 'Rise',
      low: 'Low',
      follow: 'Follow',
      phones: 'Phones',
      webSite: 'Web site',
      manager: 'General Manager',
      representative: 'Representative',
      office: 'Office',
      offices: 'Offices',
      basicInformation: 'Basic Information',
      authorization: 'Authorization',
      clientPoliticsMemories: 'Customer policy and memories',
      clientSantions: 'Sanctions Registry',
      sanctions: 'Sanctions Registry',
      // tslint:disable-next-line:max-line-length
      sanctionsDescripcion: 'This register contains the administrative sanctions that the SMV applies to the Stockbrokers, their agents, operators and other representatives (Article 147 of the Securities Market Law)',
      myVideos: 'My Videos',
      dailyMovements: 'Daily Movements',
      turnPhone: 'Turn your phone for a better experience',
      quotation: 'Quotation',
      quotations: 'Quotations',
      compare: 'Compare',
      of: 'of',
      price: 'Price',
      current: 'Current',
      currentPrice: 'Current Price',
      negotiationPriceToday: 'Negotiation price today',
      opening: 'Opening',
      closing: 'Cierre',
      max: 'Max',
      min: 'Min',
      average: 'Average',
      negotiatedQuantity: 'Negotiated quantity',
      negotiatedAmount: 'Negotiated amount',
      yesterday: 'Previous date',
      yesterdayClose: 'Previous closure',
      variation: 'Variation',
      moreInfo: 'More information',
      address: 'Address',
      businessName: 'Business name',
      fondoBusinessName: 'Denomination of investment fund',
      foundation: 'Foundation',
      dateStartOperation: 'Start date of operations',
      description: 'Description',
      stockComposition: 'Stock composition',
      directory: 'Directory',
      committee: 'Committee',
      generalManager: 'General Manager',
      indexes: 'Index',
      index: 'Index',
      issuers: 'Issuers',
      actions: 'Actions',
      buy: 'Buy',
      stock: 'Stock',
      marketSummary: 'Market summary',
      today: 'Today',
      operations: 'Operations',
      operation: 'Operation',
      amountIn: 'Amount in',
      dolars: 'Dolars',
      see: 'See',
      close: 'Close',
      representatives: 'Representatives',
      LastTradingDay: 'Last trading day',
      lastMovement: 'Last transaction',
      comparisonLimit: 'Comparison limit',
      lastDayDecember: 'December 31',
      source: 'Source',
      annualMemory: 'Annual memory',
      quotationByTime: 'The quotation data is 20 minutes late',
      getRealTimeTime: 'Get real-time information',
      urlRealTime: '/abi',
      noData: 'No data',
      noTradedShared: 'No. Traded shares or lots',
      add: 'Add',
      remove: 'Remove',
      since: 'Since',
      until: 'Until',
      bvlNews: 'Bolsa News',
      latest: 'Latest',
      financialIndexes: 'Financial Indexes',
      typeContent: 'Type of content',
      whatIs: 'What is it?',
      statistics: 'Statistics',
      components: 'Components',
      downloadDocumentos: 'Download documents',
      frequentQuestions: 'Frequent questions',
      requirements: 'Requirements',
      subjectDetail: 'Subject detail',
      periodByYear: 'Period per year',
      first: 'First',
      second: 'Second',
      third: 'Third',
      fourth: 'Fourth',
      trimester: 'Trimester',
      annual: 'Annual',
      toUp: 'To up',
      same: 'No variation',
      toDown: 'To down',
      nemonics: 'Nemonics',
      variableRent: 'Variable Rent',
      dailyBulletin: 'Daily bulletin',
      financialStatements: 'Financial statements',
      stockInformation: 'Monthly information',

      stockInformationHistorical: 'Monthly Reports in PDF Format',
      stockInformationCurrent: '',
      stockInformationAnexo: 'Annex',

      goBack: 'go back',
      goBackCapitalize: 'Go back',
      next: 'Next',
      finish: 'Finish',
      period: 'Period',
      audit: 'Audited',
      rule: 'Rule',
      socialDenomination: 'Social Denomination',
      sommelier: 'Sommelier',
      document: 'Document',
      unknown: 'UNKNOWN',
      butteinYear: 'BULLETIN YEAR',
      latestNewsletter: 'Latest newsletter: ',
      onboarding: 'onboarding',
      searchCompany: 'Search company',
      subjectType: 'Subject type',
      part: 'Part',
      feesNumber: 'Number of fees',
      termsAndConditions: 'Terms and Conditions',
      negotitationByMechanism: 'Negotitation by Mechanism',
      operationsNumber: 'Ope. Num.',
      total: 'Total',
      market: 'Market',
      numberActions: 'Shares number',
      nominalAmount: 'Nominal Amount',
      effectiveAmount: 'Effective Amount',
      socialCapital: 'Social Capital',
      offExchangeTradingOperations: 'Off Exchange Trading Operations',
      negociatedAmountsOperationsNumber: 'Negotiated Amounts and Number of Operations',
      marketFormerValues: 'Values with Former Market',
      marketFormer: 'Market Former',
      minimunAmount: 'Minimum Amount',
      without: 'without',
      with: 'with',
      former: 'former',
      dailyInformation: 'Information of today',
      formerMarketValues: 'Former Market Values',
      localMarket: 'Local Market',
      foreignMarket: 'Foreign Market',
      localValue: 'Local values',
      foreignValue: 'Foreign securities',
      summaryNegotiatedAmountsOperations: 'Summary of Negotiated Amounts and Operations',
      in: 'in',
      the: 'the',
      peruvianIntermediaries: 'Peruvian intermediaries',
      negotitation: 'negotitation',
      fromIntermediaries: 'from intermediaries',
      intermediariesFrom: 'Intermediaries from',
      action: 'Action',
      referencePrice: 'Reference price',
      updatedPrice: 'Updated price',
      mainWarranty: 'Main warranty',
      additionalGuarantee: 'Additional guarantee',
      valuationPrices: 'Valuation Prices',
      calcDate: 'Calculation date',
      effectiveDate: 'Effective<br />date',
      presence: 'Presence',
      registrationDate: 'Registration date',
      stockPresence: 'Stock presence',
      historical: 'Historical',
      fixedRent: 'Fixed Rent',
      prevDailyNewsletters: 'Previous Daily Newsletters',
      newValuesMarketFormer: 'New values ​​with market maker',
      valueName: 'Nombre del Valor',
      comment: 'Comment',
      variableIncome: 'Variable Income',
      precenseLevel: 'Presence<br />Level',
      informationDay: 'Updated information at ',
      information: 'Information',
      negociatedAmountsSellBuy: 'The amounts negotiated considering purchases and sales of Peruvian securities',
      loanValue: 'Loan value',
      guarantee: 'Garantías',
      dailyTotals: 'Daily Totals',
      // tslint:disable-next-line: max-line-length
      newMakerFormetParagraph1: '1. In reference to the number of trading sessions in which a security has a traded volume of more than 6 UITs (Tax Units) in the last 180 trading sessions, expressed as a percentage (%).',
      // tslint:disable-next-line: max-line-length
      newMakerFormetParagraph2: '2. Securities registered in the Securities Registry of Lima Stock Exchange with registration of up to 360 days and that have Market Maker, to comply with Law No. 30341, its Regulations approved by Supreme Decree No. 382-2015-EF and its amending rules; in the case of instruments with emission less than 180 days, the securities shown are those that have up to 180 days of inscription in the Registry of Securities of Lima Stock Exchange and that have Market Maker. The Status of the SAB corresponds to its status in its function as Market Maker.',
      sabPlaceholder: 'Find the SAB by name or by location pressing the button',
      functions: 'Functions',
      functionsList: 'List of functions',
      newMarketFormerNoData: 'At this moment there are no new values ​​with market maker',
      pageNotFound: 'Page not found',
      backHome: 'Back to HOME',
      issuerName: 'Name of Issuer',
      milaValuesTitle: 'MILA: List of %COUNTRY% securities that may be traded in Peru',
      peruvians: '',
      mexicans: 'Mexicans',
      chileans: 'Chileans',
      colombians: 'Colombians',
      shareholder: 'Shareholder',
      day: 'day',
      investmentFunds: 'Investment Funds',
      scrowFunds: 'Assets in Trusts',
      selectedDocuments: 'Selected documents',
      accept: 'Accept',
      cancel: 'Cancelar',
      quantity: 'Quantity',
      orderBy: 'Order by',
      presentedInformation: 'Presented información',
      select: 'Select',
      clean: 'Clean',
      excercise: 'Excercise',
      patrimonies: 'Patrimonies',
      upgrade: 'upgrade',
      exchangeRate: 'Exchange rate',
      lastUpdate: 'Last update',
      fullScreen: 'View on full screen',
      hideFullScreen: 'Normal screen',
      name: 'Name',
      mainMarket: 'Main Market',
      inscriptionDate: 'Inscription date/delisted',
      monthsNoNegotiations: 'Months without negotiations',
      createdBy: 'Enrolled by:',
      extraInformation: 'Extra Information',
      localIssuersInstruments: 'Local Issuers and Instruments',
      foreignIssuersInstruments: 'Foreign values',
      negotiationInformation: 'Negotiation Info',
      listByBVL: 'List in BVL by',
      originMarket: 'Origin market',
      listBVLdate: 'Listing date in BVL',
      attachmentDetail: 'Attachment detail',
      documentDetail: 'Document detail',
      bvlPrivacy: 'At BVL we value your privacy',
      privacyAgreementFirst: 'This website uses its own cookies that have a functional purpose and third-party cookies that allow us to know your browsing habits to give you better information services. To continue browsing, it is important that you accept their use.',
      privacyAgreementSecond: 'By continuing to use our website and clicking "Accept", you are giving your consent to the use of these technologies.',
      privacyPolitcies: 'Privacy policies',
      readMore: 'Read more',
      seeRepresentativesAgentList: 'See representatives authorized by the Regulator (SMV)',
      seeSanctionsAgentList: 'See Regulator\'s SMV sanctions registry',
      seePoliciesAgentList: 'Customer policy',
      seeMemoriesAgentList: 'Memories',
      detailAgentListUri: 'detail',
    }
  }
};
